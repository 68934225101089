"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BLOCKS = void 0;
/**
 * Map of all Contentful block types. Blocks contain inline or block nodes.
 */
var BLOCKS;
(function (BLOCKS) {
  BLOCKS["DOCUMENT"] = "document";
  BLOCKS["PARAGRAPH"] = "paragraph";
  BLOCKS["HEADING_1"] = "heading-1";
  BLOCKS["HEADING_2"] = "heading-2";
  BLOCKS["HEADING_3"] = "heading-3";
  BLOCKS["HEADING_4"] = "heading-4";
  BLOCKS["HEADING_5"] = "heading-5";
  BLOCKS["HEADING_6"] = "heading-6";
  BLOCKS["OL_LIST"] = "ordered-list";
  BLOCKS["UL_LIST"] = "unordered-list";
  BLOCKS["LIST_ITEM"] = "list-item";
  BLOCKS["HR"] = "hr";
  BLOCKS["QUOTE"] = "blockquote";
  BLOCKS["EMBEDDED_ENTRY"] = "embedded-entry-block";
  BLOCKS["EMBEDDED_ASSET"] = "embedded-asset-block";
  BLOCKS["EMBEDDED_RESOURCE"] = "embedded-resource-block";
  BLOCKS["TABLE"] = "table";
  BLOCKS["TABLE_ROW"] = "table-row";
  BLOCKS["TABLE_CELL"] = "table-cell";
  BLOCKS["TABLE_HEADER_CELL"] = "table-header-cell";
})(BLOCKS || (exports.BLOCKS = BLOCKS = {}));
