"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MARKS = void 0;
/**
 * Map of all Contentful marks.
 */
var MARKS;
(function (MARKS) {
  MARKS["BOLD"] = "bold";
  MARKS["ITALIC"] = "italic";
  MARKS["UNDERLINE"] = "underline";
  MARKS["CODE"] = "code";
  MARKS["SUPERSCRIPT"] = "superscript";
  MARKS["SUBSCRIPT"] = "subscript";
  MARKS["STRIKETHROUGH"] = "strikethrough";
})(MARKS || (exports.MARKS = MARKS = {}));
