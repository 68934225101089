import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import { SharedService } from '../../shared.service';
import { ContentfulService } from '../../../../services/contentful.service';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrl: './registration-success.component.less'
})
export class RegistrationSuccessComponent implements OnInit {

  backgroundImg = 'assets/images/img/registration_success_background.png';
  currentUser: any;
  locale = 'en-US';
  sponsorOnboardingCMSData: any;

  constructor(
    public dialogRef: MatDialogRef<RegistrationSuccessComponent>,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private contentfulService: ContentfulService,
  ) {}

  ngOnInit(): void {
    this.getLandingCMSData();
    this.authenticationService.currentUser.subscribe(data => {
      this.currentUser = data;
    });
  }

  submitDetails() {
    if(this.currentUser && this.currentUser.token) {
      this.authenticationService.logoutUser();
    } else {
      this.router.navigate(['/login']);
    }
    this.dialogRef.close();
  }

  getLandingCMSData() {
    this.sharedService.toggleGlobalLoader(true);
    this.contentfulService.getContent("35bdbvzhHRi3guRfwrXFmi", this.locale).subscribe(
      (data:any) => {
        this.sponsorOnboardingCMSData = data;
        this.backgroundImg = 'https:' + this.sponsorOnboardingCMSData.onboardingSuccessBackground.fields.file.url;
        this.sharedService.toggleGlobalLoader(false);
      }
    );
  }

}
